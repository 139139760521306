<template>
  <div class="full-area has-image" :class="{'feedback': feedback}">
    <FeedbackScreen
      :data="data"
      :feedback="feedback"
      v-on:retry="retry()"
      v-on:next="emitNext()"
    ></FeedbackScreen>

    <QuestionHeader
      :data="data"
      :no-question-title="true"
    ></QuestionHeader>

    <h2
      class="optional-question-title"
      :class="{'question-subtitle-strong': data.fields.questionImage}"
      v-if="data.fields.questionSubtitle"
    >{{data.fields.questionSubtitle}}</h2>

    <content class="content delay-entry short-delay">
      <div class="grid-wrapper">
        <div
          :span="12"
          v-for="option in options"
          :key="option"
          class="binary-box"
          :class="{'selected-box': form.selected[0] === option}"
          @click="selectThis(option)"
        >
          <div
            class="center-me"
            :class="`card_thumbs-${option}`"
          >
            <font-awesome-icon :icon="['fas', `thumbs-${option}`]" />
          </div>
        </div>
      </div>
    </content>
    <footer>
      <SkipQuestionButton
        :location="'locate-bottom'"
        v-on:skip="selectThis('S99')"
      ></SkipQuestionButton>
    </footer>
  </div>
</template>

<script>
import Vue from 'vue'

export default {
  name: 'Thumbs',

  props: [ 'data' ],

  components: {
    FeedbackScreen: () => import('Components/base/FeedbackScreen'),
    QuestionHeader: () => import('Components/base/QuestionHeader'),
    SkipQuestionButton: () => import('Components/base/SkipQuestionButton')
  },

  data () {
    return {
      feedback: '',
      locked: false,
      options: ['down', 'up'],
      form: {
        type: `thumbs`,
        identifier: this.data.fields.identifier,
        question: this.data.fields.title,
        numberOfOptions: 2,
        id: this.data.sys.id,
        selected: [],
        timeToCompletion: null,
        timestamps: {
          start: new Date(),
          finish: null,
          valid: null
        }
      }
    }
  },

  methods: {
    retry () {
      this.form.selected = []
      this.locked = false
      this.feedback = ''
    },
    emitNext () {
      this.$emit('next', this.form)
    },
    selectThis (item) {
      if (this.locked) {
        return
      }
      Vue.set(this.form, 'selected', [item])
    },
    next () {
      this.form.timestamps.finish = new Date()
      this.calculateScores()
    },
    validInput (value) {
      // Add simple validation: if it exists, it is valid.
      if (value && value.length) {
        this.form.timestamps.valid = new Date()
        this.form.timeToCompletion = this.form.timestamps.valid.getTime() - this.form.timestamps.start.getTime()
        return true
      } else {
        return false
      }
    },
    calculateScores () {
      this.locked = true
      this.$store.dispatch('CALCULATE_WEIGHTED_QUESTION', {
        formula: this.data.fields.formula,
        selected: this.form.selected,
        options: this.options,
        maxAnswers: 1,
        skip: this.data.fields.skip
      }).then(scores => {
        this.form.weighted = scores.weighted.slice(0)
        this.form.weightedMin = scores.weightedMin
        this.form.weightedMax = scores.weightedMax
        this.feedback = scores.feedback
        this.form.skip = scores.newSkip
        if (this.data.fields.randomSkip) {
          var items = this.data.fields.randomSkip.split(',')
          this.form.skip = items[items.length * Math.random() | 0]
        }
        this.emitNext()
      }).catch(err => {
        console.log('err: ', err)
      })
    }
  },

  watch: {
    // Add a simple watch to trigger next automatically after some ms
    'form.selected': function (newVal, oldVal) {
      if (this.validInput(newVal)) {
        setTimeout(() => {
          this.next()
        }, 50)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.grid-wrapper{
  .binary-box {
    padding-bottom: calc(45% * 4/5);
    @include breakpointHeight($square) {
      transform: scale(0.9);
    }
  }  
}

.content {
  margin-top: 0;
  align-items: flex-start;
}
.has-image {
  .content {
    margin-bottom: 0;
  }
}

.center-me {
  display: flex;
  flex: 1;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 5px;
  font-size: 2.5em;
}

.card_thumbs-up {
  background: $color-emphasis-alt;
}
.card_thumbs-down {
  background: $color-emphasis;
}
</style>
